.nav {
	position: absolute;
	z-index: 99;
	left: 0;
	right: 0;
}
.uk-background-secondary,
.uk-section-secondary {
  background: rgba(30, 47, 64, 1);
}
.uk-sticky-placeholder {
	height: 0 !important;
}
.top-wrap::before, .overlay-wrap::before {
	position: absolute;
	top:0;
	right:0;
	left: 0;
	bottom: 0;
	content: '';
	z-index: 1;
	background-color: rgba(28, 44, 57, 0.7);
}
.top-wrap-height {
	height: 80vh;
	min-height: 460px;
	transition: height 0.25s;
}
.uk-subnav-pill > * > :first-child {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.04);
}
.top-container {
	z-index: 2;
}
.uk-logo img {
	height: 55px;
}
.subtitle-text {
	font-size: 1.2em;
	opacity: 0.7;
	font-weight: 300;
}
.logos-grid img {
	display: block;
	width: 60px;
	margin: 0 auto;
	filter:  grayscale(100%);
	transition: filter 0.25s;
}
.logos-grid img:hover {
	display: block;
	width: 60px;
	margin: 0 auto;
	filter:  grayscale(0);
}

.uk-light p {
	color: #f5f5f6;
}

.uk-light .uk-button-primary {
	background-color: #1e87f0;
    color: #fff;
}

.uk-fill-height {
	height: 100vh;
}

.uk-offcanvas-bar {
	li {
		a {
			font-size: 22px;
			font-weight: 100;
			margin-bottom: 20px;
		}
	}
}
